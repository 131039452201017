<template>
  <div class="payment-history">
    <h2 class="payment-history__title">История</h2>
    <ChipList class="payment-history__filter" select :items="filters" v-model="filter" :can-deselect="false" />

    <div v-if="!groupedHistory?.length" class="no-items">
      <div class="title small">Транзакции отсутствуют</div>
      <BaseButton color-primary v-if="filter !== filters[0].id" @click="filter = filters[0].id">Показать все</BaseButton>
    </div>
    <div v-else class="payment-history__list">
      <template v-for="group in groupedHistory">
        <div class="payment-history__date">{{ formatDate(group.timestamp) }}</div>
        <div class="payment-history__group">
          <PaymentRecord v-for="record in group.records" :key="record.id" :record="record" />
        </div>
      </template>
    </div>

  </div>
</template>

<script setup>
import { getPaymentHistory } from '~/api/payment'
import { groupBy, formatDate } from '~/utils/dayjs'

const filters = [
  { id: 'all', name: 'Все' },
  { id: 'refill', name: 'Зачисления' },
  { id: 'payout', name: 'Вывод' }
]
const filter = ref(filters[0].id)

const { data: history, refresh } = await useAsyncData(getPaymentHistory)
if (!history.value) throw createInternalError()

defineExpose({ refresh })

const filteredHistory = computed(() => history.value.filter(
  r => {
    switch (filter.value) {
      case 'refill': return r.coins >= 0
      case 'payout': return r.is_payout
      default: return true
    }
  }
))
const groupedHistory = computed(() => groupBy('day', filteredHistory.value, r => r.created_at))
</script>

<style scoped lang="scss">
.payment-history {
  &__title {
    font-size: 36px;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 18px;
    @media (max-width: 920px) {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
  }
  &__filter {
    margin-bottom: 18px;
    @media (max-width: 920px) {
      margin-bottom: 24px;
    }
  }
  &__date {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-elements-primary);
  }
}
</style>
