
export function groupBy (unit, records, getter = record => record) {
  const dayjs = useDayjs()
  const grouped = []
  records.forEach(record => {
    const timestamp = dayjs(getter(record))
    const group = grouped.find(g => g.timestamp.isSame(timestamp, unit))
    if (group) return group.records.push(record)

    grouped.push({
      timestamp: timestamp.startOf(unit),
      records: [record]
    })
  })
  return grouped
}

export function formatDate (date) {
  const dayjs = useDayjs()
  return dayjs(date).format('D MMMM' + (dayjs(date).isSame(dayjs(), 'year') ? '' : ' YYYY'))
}

export function formatTime (date) {
  const dayjs = useDayjs()
  return dayjs(date).format('H:mm')
}

export function formatDateTime (date) {
  return formatDate(date) + ', ' + formatTime(date)
}
