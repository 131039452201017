<template>
  <div class="payment-cards">
    <div v-if="title" class="payment-cards__header">
      <h3 class="payment-cards__title">{{ title }}</h3>
      <DesktopOnly>
        <LinkButton @click.prevent="addCard" :class="{ pending }">Новая карта<IconPlus class="icon" /></LinkButton>
      </DesktopOnly>
    </div>
    <div class="payment-cards__list">
      <PaymentCard
        v-for="card in cards"
        :key="card.id"
        :card="card"
        :active="card.id === selected"
        @click="selected = card.id"
        @remove="removeCard"
      />
      <MobileOnly>
        <PaymentCard create @click.prevent="addCard" :class="{ pending }" />
      </MobileOnly>
    </div>
  </div>
</template>

<script setup>
import { IconPlus } from '@tabler/icons-vue'
import { getPaymentAddCardUrl, removePaymentCard } from '~/api/payment'

const props = defineProps({
  title: String,
  cards: Array,
  modelValue: [String, Number]
})
const emit = defineEmits(['update:modelValue', 'refresh'])

const selected = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

watchEffect(() => {
  if (props.cards?.length && !props.cards.find(c => c.id === selected.value)) {
    selected.value = (props.cards.find(c => c.general) ?? props.cards[0]).id
  }
})

const {
  loading: pending,
  submit: addCard
} = useForm(async () => {
  const { url } = await getPaymentAddCardUrl()
  window.open(url)
})

async function removeCard (id) {
  await removePaymentCard(id)
  emit('refresh')
}
</script>

<style scoped lang="scss">
.payment-cards {
  margin-top: 24px;

  &__header {
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 920px) {
      padding: 0;
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    color: var(--color-elements-primary);
    @media (max-width: 920px) {
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
    }
  }

  &__list {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    @media (max-width: 920px) {
      margin-top: 16px;
      gap: 8px;
      display: flex;
      align-items: center;
      overflow-y: hidden;
      overflow-x: auto;
      width: calc(100% + 30px);
      margin-left: -15px;
      padding: 0 15px;
      > * {
        height: 63px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &:empty {
      display: none;
    }
  }
}
</style>
