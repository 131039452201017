<template>
  <div v-if="meta?.length" class="payment-record-meta">
    <div v-for="item in meta" :key="item.name" class="payment-record-meta__item">
      <div class="payment-record-meta__name">{{ item.name }}</div>
      <div class="payment-record-meta__value">{{ item.value }}</div>
    </div>
  </div>
</template>

<script setup>
import { PayoutStatus } from '~/utils/payment'

const props = defineProps({
  record: Object
})

const fullName = u => u.first_name + ' ' + u.last_name

const meta = computed(() => {
  if (props.record.is_payout) return [
    { name: 'Вывод средств на', value: `** ${(props.record.card + '').slice(-4)}` },
    { name: 'Статус', value: PayoutStatus[props.record.status] ?? 'Обратитесь к администратору' },
    { name: 'Идентификатор выплаты', value: props.record.payment_id }
  ]

  if (['bank', 'achievement', 'bonus'].includes(props.record.type))
    return [
      {
        name: 'Тип зачисления',
        value: {
          bank: 'Банк',
          achievement: `Достижение "${props.record.achievement?.title}"`,
          bonus: 'Бонус'
        }[props.record.type]
      }
    ].concat(props.record.payment_id ? [{ name: 'Идентификатор платежа', value: props.record.payment_id }] : [])

  if (props.record.menti || props.record.mentor)
    return [
      { name: 'Оплата занятия', value: fullName(props.record.mentor ?? props.record.menti) },
    ]

  if (props.record.responded)
    return [
      { name: 'Покупка контактов специалиста', value: fullName(props.record.responded) }
    ]

  return []
})
</script>

<style scoped lang="scss">
.payment-record-meta {
  display: flex;
  flex-direction: column;
  gap: 2px;

  &__item {
    display: flex;
    justify-content: space-between;
    gap: 0 16px;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-elements-secondary);
  }
  &__value {
    font-weight: 600;
  }
}
</style>
