<template>
  <div class="payment-record">
    <component :is="icon" class="payment-record__icon" />
    <div class="payment-record__body">
      <div class="payment-record__amount">
        {{ record.coins >= 0 ? '+' : '-' }}
        {{ Math.abs(record.coins) }} ₽
      </div>
      <div class="payment-record__desc">
        <time>{{ formatDateTime(record.created_at) }}</time>
      </div>
      <PaymentRecordMeta class="payment-record__meta" :record="record" />
    </div>
  </div>
</template>

<script setup>
import IconWithdraw from '~/components/icons/IconWithdraw.vue'
import IconAdd from '~/components/icons/IconAdd.vue'
import IconRemove from '~/components/icons/IconRemove.vue'
import { formatDateTime } from '~/utils/dayjs'

const props = defineProps({
  record: Object
})

const dayjs = useDayjs()

const isRefill = computed(() => props.record.coins >= 0)
const icon = computed(() => {
  if (props.record.is_payout) return IconWithdraw
  if (isRefill.value) return IconAdd
  return IconRemove
})
</script>

<style scoped lang="scss">
.payment-record {
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  @media (max-width: 920px) {
    padding: 16px 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-separator);
  }

  &__icon {
    flex-shrink: 0;
    width: 42px;
    height: 42px;
    @media (max-width: 920px) {
      width: 36px;
      height: 36px;
    }
  }

  &__body {
    flex: 1;
  }
  &__amount {
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    color: var(--color-elements-primary);
    @media (max-width: 920px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__desc {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-elements-secondary);
  }
  &__meta {
    margin-top: 8px;
  }
}
</style>
