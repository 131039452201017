import creditCardType from 'credit-card-type'

export const PayoutStatus = {
  NEW: 'В процессе',
  CHECKING: 'В процессе',
  CHECKED: 'В процессе',
  COMPLETING: 'В процессе',
  COMPLETED: 'Выполнено',
  REJECTED: 'Отклонен',
  CREDIT_CHECKING: 'В процессе'
}

export function getCardType (cardNumber) {
  return creditCardType(cardNumber)?.[0]
}
