<template>
  <form ref="formRef" class="payment-form" @submit.prevent="submit">
    <Tabs v-if="user.is_mentor" bottom fluid :tabs="[
        { name: 'Пополнить' },
        { name: 'Вывести' }
      ]" v-model="tab" class="payment-form__tabs" />

    <div class="payment-form__body">
      <div class="payment-form__header">
        <h2 class="payment-form__title">{{ tab === 1 ? 'Доступно к выводу' : 'Пополнить баланс' }}</h2>
        <div v-if="tab === 1" class="payment-form__hint">
          <span>{{ user.coins_payout }} ₽ без комиссии</span>
          <Hint>К выводу без комиссии доступны лишь заработанные на платформе средства</Hint>
        </div>
      </div>

      <input type="hidden" name="frame" value="false">
      <input type="hidden" name="language" value="ru">
      <input type="hidden" name="description" value="Пополнение баланса">
      <input type="hidden" name="name"
             :value="`${user.personal.first_name} ${user.personal.last_name} ${user.personal.middle_name ?? ''}`">
      <input type="hidden" name="email" :value="user.email">
      <input type="hidden" name="phone" :value="user.phone ?? ''">
      <input type="hidden" name="order" :value="form.order_id">
      <input type="hidden" name="terminalkey" :value="form.terminal_key">

      <AtomInput
        name="Сумма"
        form-name="amount"
        symbol="₽"
        :desc="amountDesc"
        :process-field="processField"
        v-model="form.amount"
        :error="errors.amount"
      />
      <ChipList class="payment-form__shortcuts">
        <template v-for="shortcut in shortcuts">
          <Chip
            type="button"
            v-if="tab !== 1 || shortcut <= user.coins_total"
            :key="shortcut"
            @click="form.amount = shortcut"
            :selected="form.amount === shortcut"
          >{{ shortcut }}</Chip>
        </template>
      </ChipList>
      <PaymentCardList v-if="tab === 1" title="Вывести на карту" :cards="cards" v-model="form.card_id" @refresh="cardsRefresh" />
      <BaseButton big stretch class="payment-form__submit" type="submit" :class="{ pending, disabled: tab === 1 && !form.card_id }">{{ tab === 1 ? 'Вывести средства' : 'Пополнить баланс' }}</BaseButton>
    </div>
  </form>
</template>

<script setup>
import { calculatePayout, createPayout, getPaymentCards, getPaymentData } from '~/api/payment'

const emit = defineEmits(['refresh'])

const MIN_AMOUNT = 10

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const formRef = ref()
const shortcuts = reactive([1500, 2000, 3000, 5000])
const tab = ref(0)
const processField = value => value.toString().replace(/\D/g, '')

const min = computed(() => tab.value === 1 ? (payout.value?.min_payout_coins ?? 350) : MIN_AMOUNT)
const minNotice = computed(() => min.value ? `Минимальная сумма к ${tab.value === 1 ? 'выводу' : 'пополнению'} - ${min.value}₽` : '')
const commisionNotice = computed(() => payout.value?.total_commission_coins ? `Комиссия - ${payout.value.total_commission_coins}₽` : '')
const amountDesc = computed(() => tab.value === 1 && commisionNotice.value ? commisionNotice.value : minNotice.value)

const {
  form,
  errors,
  loading: pending,
  submit
} = useForm(async (data) => {
  if (!data.amount || min.value > data.amount) throw { errors: { amount: minNotice.value } }

  if (tab.value === 1) {
    if (!payout.value?.status) throw { errors: { amount: commisionNotice.value } }
    return await createPayout(data)
  }

  form.value = { ...form.value, ...await getPaymentData() }
  await nextTick()
  return await window.pay?.(formRef.value)
}, () => {
  form.value = {}
  errors.value = {}
  emit('refresh')
  updateUserInfo()
})


const { data: payout, refresh: payoutRefresh } = await useAsyncData(() => calculatePayout(form.value.amount), { immediate: false, default: null })

watch(tab, tab => {
  form.value = {}
  errors.value = {}
  if (tab === 1) payoutRefresh()
})
watchDebounced(() => form.value.amount, () => {
  errors.value = {}
  payoutRefresh()
}, { debounce: 800 })


const { data: cards, refresh: cardsRefresh } = await useAsyncData(
  async () => user.value.is_mentor ? await getPaymentCards() : [],
  { transform: arr => arr.sort((a, b) => b.general - a.general) }
)

useScriptTag('https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js')
</script>

<style scoped lang="scss">
.payment-form {
  border-radius: 16px;
  border: 1px solid var(--color-separator);
  @media (max-width: 920px) {
    border: none;
    border-radius: 0;
  }

  &__body {
    padding: 24px;
    color: var(--color-elements-primary);
    @media (max-width: 920px) {
      padding: 32px 15px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px 24px;
    margin-bottom: 24px;
  }
  &__title {
    font-size: 28px;
    font-weight: 900;
    line-height: 38px;
    @media (max-width: 920px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__hint {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__shortcuts {
    margin-top: 8px;
  }
  &__cards {
    margin-top: 24px;
  }
  &__submit {
    margin-top: 24px;
  }
}
</style>
