<template>
  <img v-if="type === 'visa'" src="~/assets/img/cards/visa.svg" alt="Visa" v-bind="$attrs">
  <img v-else-if="type === 'mastercard'" src="~/assets/img/cards/mastercard.svg" alt="Mastercard" v-bind="$attrs">
  <img v-else-if="type === 'mir'" src="~/assets/img/cards/mir.svg" alt="Мир" v-bind="$attrs">
</template>

<script setup>
import { getCardType } from '~/utils/payment'

const props = defineProps({
  number: String
})

const type = computed(() => getCardType(props.number)?.type)
</script>

<style scoped lang="scss">

</style>
