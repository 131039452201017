<template>
  <div class="payment-card" :class="{ 'payment-card--active': active, 'payment-card--create': create }" v-bind="$attrs">
    <template v-if="create">
      <IconPlus class="icon" />
      <span>Новая карта</span>
    </template>
    <template v-else>
      <div class="payment-card__header">
        <IconCreditCard class="payment-card__icon" />
        <PaymentCardTypeIcon class="payment-card__type" :number="card.pan" />
      </div>
      <div class="payment-card__body">
        <div class="payment-card__number">{{ shortPan }}</div>
        <PopperMenu
          @click.stop
          placement="bottom"
        >
          <IconDots class="payment-card__more" />
          <template #header>{{ shortPan }}</template>
          <template #content>
            <PopperMenuItem danger @click.prevent="remove"><IconTrash class="icon" />Удалить</PopperMenuItem>
          </template>
        </PopperMenu>
      </div>
    </template>
  </div>
  <ClientOnly><PromptModal @provide="prompt = $event"/></ClientOnly>
</template>

<script setup>
import { IconCreditCard, IconDots, IconTrash, IconPlus } from '@tabler/icons-vue'

const props = defineProps({
  card: Object,
  active: Boolean,
  create: Boolean
})
const emit = defineEmits(['remove'])

const shortPan = computed(() => '** ' + props.card.pan.slice(-4))

const prompt = ref()

async function remove () {
  if (
    await prompt.value({
      title: `Вы уверены, что хотите удалить карту ${shortPan.value}?`,
      accept: 'Удалить',
      danger: true
    })
  ) emit('remove', props.card.id)
}
</script>

<style scoped lang="scss">
.payment-card {
  user-select: none;
  cursor: pointer;
  aspect-ratio: 1.9;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid var(--color-elements-quantery);
  @media (max-width: 920px) {
    padding: 8px;
    border-radius: 8px;
    aspect-ratio: 1.7;
  }
  &:hover {
    border-color: var(--color-elements-tertiary);
  }
  &--active {
    cursor: default;
    border-color: transparent !important;
    box-shadow: inset 0 0 0 3px var(--color-primary);
    @media (max-width: 920px) {
      box-shadow: inset 0 0 0 2px var(--color-primary);
    }
  }
  &--create {
    justify-content: center;
    align-items: center;
    gap: 2px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: var(--color-elements-secondary);
    .icon {
      width: 18px;
      height: 18px;
      color: var(--color-elements-primary);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__icon {
    color: var(--color-elements-tertiary);
    @media (max-width: 920px) {
      width: 16px;
      height: 16px;
    }
  }
  &__type {
    height: 24px;
    @media (max-width: 920px) {
      height: 16px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__number {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-elements-primary);
    @media (max-width: 920px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  &__more {
    cursor: pointer;
    color: var(--color-elements-secondary);
    transition: .2s color;
    &:hover {
      color: var(--color-elements-primary);
    }
    @media (max-width: 920px) {
      transform: translateY(4px);
    }
  }
}
</style>
