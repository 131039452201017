<template>
  <main class="payment">
    <div class="horiz-container default-grid row-gap">
      <div class="payment__header">
        <h1 class="payment__title">Баланс</h1>
        <div class="payment__coins">{{ user.coins_total }} ₽</div>
      </div>
      <div class="payment__form">
        <PaymentForm @refresh="history?.refresh?.()" />
      </div>
      <PaymentHistory ref="history" class="payment__history" />
    </div>
  </main>
</template>

<script setup>
const user = inject('user')

useHead({
  title: 'Баланс'
})

const history = ref()
</script>

<style scoped lang="scss">
.payment {
  padding-top: 0;
  display: grid;
  @media (max-width: 920px) {
    background: var(--color-bg-secondary);
  }
  .horiz-container {
    grid-template-rows: auto 1fr;
    @media (max-width: 920px) {
      grid-template-rows: auto;
      row-gap: 8px;
      padding: 0;
    }
  }
  &__header, &__form {
    grid-column: 2 / 8;
    background: var(--color-bg);
    @media (max-width: 1110px) {
      grid-column: 3 / 11;
    }
    @media (max-width: 920px) {
      grid-column: span 12;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 80px;
    @media (max-width: 920px) {
      padding: 16px 15px;
      margin-top: 0;
    }
  }
  &__title {
    font-size: 36px;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
    color: var(--color-elements-primary);
    @media (max-width: 920px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  &__coins {
    font-size: 28px;
    font-weight: 900;
    line-height: 38px;
    color: var(--color-primary);
    @media (max-width: 920px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__form {
    padding-bottom: 80px;
    @media (max-width: 1110px) {
      padding-bottom: 0;
    }
  }
  &__history {
    position: relative;
    grid-column: 10 / 13;
    grid-row: 1 / 3;
    padding-top: 32px;
    padding-bottom: 80px;
    background: var(--color-bg);
    @media (max-width: 1330px) {
      grid-column: 9 / 13;
    }
    @media (max-width: 1110px) {
      grid-row: span 1;
      grid-column: 4 / 10;
    }
    @media (max-width: 920px) {
      grid-column: span 12;
      padding: 32px 15px 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: calc(100% + 40px);
      height: 100%;
      width: 1px;
      background: var(--color-separator);
      @media (max-width: 1110px) {
        display: none;
      }
    }
  }
}
</style>
